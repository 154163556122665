import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageHeader, Layout, Form, Input, Button, notification, Alert } from 'antd';
import { CheckOutlined, LockOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';
import TextArea from 'antd/lib/input/TextArea';

const { Content } = Layout;

const Page = () => {

    const { isLoading, error, data, sendRequest } = useHttp();

    const match = useRouteMatch('/pages/:id');
    const history = useHistory();
    const [id] = useState(match.params.id);
    const [validationErrors, setValidationErrors] = useState('');
    const [reference, setReference] = useState('');

    const formRef = useRef();

    const handleSubmit = values => {
        setValidationErrors('');
        const graphqlQuery = {
            query: `
                    mutation UpdatePage ($pageId: ID!, $reference: String!, $title: String!, $description: String!, $keywords: String!, $details: String){
                        updatePage(id: $pageId,
                            pageInput:{
                                reference: $reference
                                title: $title
                                description: $description
                                keywords: $keywords
                                details: $details
                            }
                        )
                        {
                            _id
                            title
                        }
                    }
                    `,
            variables: {
                pageId: id,
                reference: values.reference,
                title: values.title,
                description: values.description,
                keywords: values.keywords,
                details: values.details
            }
        };
        sendRequest(
            graphqlQuery,
            'UPDATE_PAGE',
        );
    };

    const onFillForm = args => {
        formRef.current.setFieldsValue({
            reference: args.reference,
            title: args.title,
            description: args.description,
            keywords: args.keywords,
            details: args.details
        });
    };

    const getPage = useCallback(() => {
        const graphqlQuery = {
            query: `
          query Page ($pageId: ID!){
            page(id: $pageId){
                _id
                reference
                title
                description
                keywords
                details
            }
          }
        `,
            variables: {
                pageId: id
            }
        };

        sendRequest(
            graphqlQuery,
            'GET_PAGE',
        );
    }, [id, sendRequest]);

    useEffect(() => {
        if (id !== 'new') {
            getPage();
        }
    }, [id, getPage]);

    useEffect(() => {
        if (data) {
            if (data.page) {
                setReference(data.page.reference);
                const args = [];
                args.reference = data.page.reference;
                args.title = data.page.title;
                args.description = data.page.description;
                args.keywords = data.page.keywords;
                args.details = data.page.details;
                onFillForm(args);
            }
            if (data.updatePage) {
                notification.open({
                    message: 'Registro atualizado com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/pages/');
            }
            if (data.createPage) {
                notification.open({
                    message: 'Registro inserido com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/pages/');
            }
        }
    }, [data, history]);

    useEffect(() => {
        if (error) {
            if (error[0].status === 401) {
                history.replace('/');
                localStorage.clear();
                notification.open({
                    message: 'Não Autorizado!',
                    description:
                        'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
                    icon: <LockOutlined style={{ color: '#B22222' }} />,
                    onClick: () => {
                        history.replace('/');
                        localStorage.clear();
                    },
                    duration: 1
                });
            }
            if (error[0].status === 422) {
                const valErrors = [];
                for (let i = 0; i < error[0].data.length; i++) {
                    let message = error[0].data[i].message;
                    if (message.includes('Description must be 120 to 160 characters.'))
                        message = 'Descrição deve ter entre 120 e 160 caracteres.';
                    valErrors.push(message);
                }
                if (validationErrors === undefined) {
                    setValidationErrors(valErrors + '\n');
                } else {
                    setValidationErrors(validationErrors + valErrors + '\n');
                }
            }
            if (error[0].status === 11000) {
                let err;
                if (error[0].message.includes('duplicate key error collection') === true) {
                    err = 'Título duplicado.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 404) {
                if (error[0].message === `No page found!`) {
                    notification.open({
                        message: 'Página não encontrada',
                        description:
                            'Você foi direcionado para Páginas',
                        icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
                        duration: 3
                    });
                    history.replace('/pages/');
                }
            }
            if (error[0].status === 500) {
                if (id !== 'new') {
                    if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
                        notification.open({
                            message: 'Página não encontrada',
                            description:
                                'Você foi direcionado para Páginas',
                            icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
                            duration: 3
                        });
                        history.replace('/pages/');
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <Layout>
            <PageHeader
                ghost={false}
                onBack={() => history.replace('/pages/')}
                title="Páginas"
                subTitle={reference === null ? 'Novo registro' : reference}></PageHeader>
            <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
                <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>

                    <Form.Item label="Referência" name="reference"
                        rules={[{
                            required: true,
                            message: 'Favor preencher a Referência!'
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Título" name="title"
                        rules={[{
                            required: true,
                            message: 'Favor preencher o Título!'
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Descrição" name="description"
                        rules={[{
                            required: true,
                            message: 'Favor preencher a Descrição entre 120 e 160 caracteres!'
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Palavras-chave" name="keywords"
                        rules={[{
                            required: true,
                            message: 'Favor preencher as Palavras-chave!'
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Detalhes" name="details"
                        rules={[{
                            required: true,
                            message: 'Favor preencher os Detalhes!'
                        }]}>
                        <TextArea rows={10} />
                    </Form.Item>
                    {
                        validationErrors !== '' ?
                            <Form.Item {...tailFormItemLayout}>
                                <Alert
                                    message="Verifique os seguintes erros"
                                    description={validationErrors}
                                    type="error"
                                    showIcon
                                />
                            </Form.Item> : null
                    }
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Salvar
                            </Button>
                    </Form.Item>
                </Form>
            </Content>
            <Footer />
        </Layout>
    );
}

export default Page;