import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  PageHeader, Layout, Form, Input, InputNumber, Button,
  notification, Alert, Upload, Switch, Transfer, Card,
  message, Col, Row, Modal, Tabs, Select
} from 'antd';
import {
  CheckOutlined, LockOutlined, InboxOutlined, BarcodeOutlined,
  DeleteFilled//, WarningFilled
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;
const { Dragger } = Upload;
const { TabPane } = Tabs;
const { Option } = Select;


const Product = () => {

  const { isLoading, error, data, sendRequest } = useHttp();

  const match = useRouteMatch('/products/:id');
  const history = useHistory();
  const [id] = useState(match.params.id);
  const [validationErrors, setValidationErrors] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState(null);
  const [location, setLocation] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState(null);
  const [checkShipping, setCheckShipping] = useState(true);
  const [isPromotion, setIsPromotion] = useState(true);
  const [isActivated, setIsActivated] = useState(true);
  const [isFeatured, setIsFeatured] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [unitOfMeasurements, setUnitOfMeasurements] = useState([]);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [imageToDelete, setImageToDelete] = useState('');

  const propsUploadImage = {
    name: 'image',
    action: `${process.env.REACT_APP_API_URL}/image`,
    multiple: false,
    method: 'PUT',
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} carregado com sucesso!`);

        setMainImage(info.fileList[info.fileList.length - 1].response.file);
        let uploadedFiles = info.fileList[info.fileList.length - 1].response.file;
        console.log('Uploaded Files', uploadedFiles);
        const graphqlQuery = {
          query: `
                    mutation StorageProductImage ($productId: ID!, $originalFile: String!){
                        storageProductImage (id: $productId, 
                            storageProductImageInput:{
                                originalFile: $originalFile
                            }
                        )
                        {
                            image
                        }
                    }
                    `,
          variables: {
            productId: id,
            originalFile: info.fileList[info.fileList.length - 1].response.file,
          }
        };
        sendRequest(
          graphqlQuery,
          'UPDATE_IMAGE',
        );
      }
      else if (info.file.status === 'error') {
        message.error(`Erro ao carregar arquivo ${info.file.name}.`);
      }
    },
  };

  const propsUploadImages = {
    name: 'image',
    action: `${process.env.REACT_APP_API_URL}/image`,
    multiple: false,
    method: 'PUT',
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} carregado com sucesso!`);

        let uploadedFiles = info.fileList[info.fileList.length - 1].response.file;
        console.log('Uploaded Files', uploadedFiles);
        const graphqlQuery = {
          query: `
                    mutation StorageProductImages ($productId: ID!, $files: String!){
                        storageProductImages (id: $productId, 
                            storageProductImagesInput:{
                                files: $files
                            }
                        )
                        {
                            status
                        }
                    }
                    `,
          variables: {
            productId: id,
            files: uploadedFiles
          }
        };
        sendRequest(
          graphqlQuery,
          'UPDATE_IMAGE',
        );
      }
      else if (info.file.status === 'error') {
        message.error(`Erro ao carregar arquivo ${info.file.name}.`);
      }
    },
  };

  const selectToDelete = image => {
    setVisibleDelete(!visibleDelete);
    setImageToDelete(image);
  };

  const deleteData = () => {
    // if (imageList.length === 1) {
    //     notification.open({
    //         message: 'Erro ao excluir imagem secundária',
    //         description:
    //             'É necessário pelo menos uma imagem secundária.',
    //         icon: <WarningFilled style={{ color: '#B22222' }} />,
    //         duration: 4
    //     });
    //     setVisibleDelete(false);
    // }
    // else {
    const graphqlQuery = {
      query: `
                  mutation DeleteProductImage ($productId: ID!, $image: String!){
                    deleteProductImage(id: $productId, image: $image)
                }
                `,
      variables: {
        productId: id,
        image: imageToDelete
      }
    };
    sendRequest(
      graphqlQuery,
      'DELETE_IMAGE',
    );
    // }
  };

  const deleteCancel = () => {
    setVisibleDelete(!visibleDelete);
  };

  const handleChangeCategory = value => {
    setCategory(value);
  };

  const handleChangeLocation = value => {
    setLocation(value);
  };

  const handleChangeManufacturer = value => {
    setManufacturer(value);
  };

  const handleChangeUnitOfMeasurement = value => {
    setUnitOfMeasurement(value);
  };

  const formRef = useRef();

  const [targetKeywords, setTargetKeywords] = useState([]);

  const onCheckShippingChange = checked => {
    setCheckShipping(checked);
  };

  const onIsPromotionChange = checked => {
    setIsPromotion(checked);
  };

  const onIsFeaturedChange = checked => {
    setIsFeatured(checked);
  };

  const onIsActivatedChange = checked => {
    setIsActivated(checked);
  };

  const handleSubmit = values => {
    setValidationErrors('');
    if (id !== 'new') {
      // UPDATE
      const graphqlQuery = {
        query: `
                                mutation UpdateProduct ($productId: ID!, 
                                    $title: String!,
                                    $description: String!,
                                    $option: String!,
                                    $details: String!,
                                    $oldPrice: Float!,
                                    $price: Float!,
                                    $weight: Float!,
                                    $width: Int!,
                                    $height: Int!,
                                    $depth: Int!,
                                    $deadline: Int!,
                                    $checkShipping: Boolean!,
                                    $isPromotion: Boolean!,
                                    $isFeatured: Boolean!,
                                    $isActivated: Boolean!,
                                    $category: ID!,
                                    $location: ID!,
                                    $manufacturer: ID!,
                                    $unitOfMeasurement: ID!){
                                    updateProduct(id: $productId,
                                        productInput:{
                                            title: $title,
                                            description: $description,
                                            option: $option,
                                            details: $details,
                                            oldPrice: $oldPrice,
                                            price: $price,
                                            weight: $weight,
                                            width: $width,
                                            height: $height,
                                            depth: $depth,
                                            deadline: $deadline,
                                            checkShipping: $checkShipping,
                                            isPromotion: $isPromotion,
                                            isFeatured: $isFeatured,
                                            isActivated: $isActivated,
                                            category: $category,
                                            location: $location,
                                            manufacturer: $manufacturer,
                                            unitOfMeasurement: $unitOfMeasurement
                                        }
                                    )
                                    {
                                        _id
                                        title
                                        description
                                        option
                                        details
                                        oldPrice
                                        price
                                        weight
                                        width
                                        height
                                        depth
                                        deadline
                                        checkShipping
                                        isPromotion
                                        isFeatured
                                        isActivated
                                        category {
                                            _id
                                            title
                                        }
                                        location {
                                            _id
                                            title
                                        }
                                        manufacturer {
                                            _id
                                            title
                                        }
                                        unitOfMeasurement {
                                            _id
                                            singular
                                        }
                                    }
                                }
                                `,
        variables: {
          productId: id,
          title: values.title,
          description: values.description,
          option: values.option,
          details: values.details,
          oldPrice: values.oldPrice,
          price: values.price,
          weight: values.weight,
          width: values.width,
          height: values.height,
          depth: values.depth,
          deadline: values.deadline,
          checkShipping,
          isPromotion,
          isFeatured,
          isActivated,
          category,
          location,
          manufacturer,
          unitOfMeasurement
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_PRODUCT',
      );
    }
    else {
      const graphqlQuery = {
        query: `
                    mutation createProduct (
                        $title: String!,
                        $description: String!,
                        $option: String!,
                        $details: String!,
                        $oldPrice: Float!,
                        $price: Float!,
                        $weight: Float!,
                        $width: Int!,
                        $height: Int!,
                        $depth: Int!,
                        $deadline: Int!,
                        $checkShipping: Boolean!,
                        $isPromotion: Boolean!,
                        $isFeatured: Boolean!,
                        $isActivated: Boolean!,
                        $category: ID!,
                        $location: ID!,
                        $manufacturer: ID!,
                        $unitOfMeasurement: ID!){
                        createProduct(
                            productInput:{
                                title: $title,
                                description: $description,
                                option: $option,
                                details: $details,
                                oldPrice: $oldPrice,
                                price: $price,
                                weight: $weight,
                                width: $width,
                                height: $height,
                                depth: $depth,
                                deadline: $deadline,
                                checkShipping: $checkShipping,
                                isPromotion: $isPromotion,
                                isFeatured: $isFeatured,
                                isActivated: $isActivated,
                                category: $category,
                                location: $location,
                                manufacturer: $manufacturer,
                                unitOfMeasurement: $unitOfMeasurement
                            }
                        )
                        {
                            _id
                            title
                            description
                            option
                            details
                            price
                            checkShipping
                            isPromotion
                            isActivated
                            isFeatured
                        }
                    }
                `,
        variables: {
          productId: id,
          title: values.title,
          description: values.description,
          option: values.option,
          details: values.details,
          oldPrice: values.oldPrice,
          price: values.price,
          weight: values.weight,
          width: values.width,
          height: values.height,
          depth: values.depth,
          deadline: values.deadline,
          checkShipping,
          isPromotion,
          isFeatured,
          isActivated,
          category,
          location,
          manufacturer,
          unitOfMeasurement
        }
      };
      sendRequest(
        graphqlQuery,
        'CREATE_PRODUCT',
      );
    }
  };

  const onFillForm = args => {
    setCheckShipping(args.checkShipping);
    setIsPromotion(args.isPromotion);
    setIsFeatured(args.isFeatured);
    setIsActivated(args.isActivated);
    formRef.current.setFieldsValue({
      title: args.title,
      description: args.description,
      option: args.option,
      details: args.details,
      oldPrice: args.oldPrice,
      price: args.price,
      weight: args.weight,
      width: args.width,
      height: args.height,
      depth: args.depth,
      deadline: args.deadline,
      category: args.category,
      location: args.location,
      manufacturer: args.manufacturer,
      unitOfMeasurement: args.unitOfMeasurement
    });
  };

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;


  const handleKeywordsChange = (targetKeys, direction, moveKeys) => {
    if (direction === 'left') {
      // DELETE
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{productId: "${id}", keywordId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
                    mutation { deleteProductKeywordMultiple(productKeywordInput: 
                            [
                            ${array}
                            ]
                        )
                    }
                    `
      };
      sendRequest(
        graphqlQuery,
        'DELETE_PRODUCT_KEYWORD',
      );
    }
    else if (direction === 'right') {
      // INSERT
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{productId: "${id}", keywordId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
                    mutation { createProductKeywordMultiple(productKeywordInput: 
                            [
                            ${array}
                            ]
                        )
                    }
                    `
      };
      sendRequest(
        graphqlQuery,
        'CREATE_PRODUCT_KEYWORD',
      );
    }
  };

  const getKeywords = useCallback(() => {
    const graphqlQuery = {
      query: `
              query Keywords {
                keywords (sortField: "title", sortOrder: "ASC"){
                    keywords{
                      _id
                      title
                    }
                  }
              }
            `,
    };

    sendRequest(
      graphqlQuery,
      'GET_KEYWORDS',
    );
  }, [sendRequest]);

  const getCategories = useCallback(() => {
    const graphqlQuery = {
      query: `
              query Categories {
                categories (sortField: "title", sortOrder: "ASC"){
                    categories {
                      _id
                      title
                    }
                  }
              }
            `,
    };

    sendRequest(
      graphqlQuery,
      'GET_CATEGORIES',
    );
  }, [sendRequest]);

  const getLocations = useCallback(() => {
    const graphqlQuery = {
      query: `
              query Locations {
                locations (sortField: "title", sortOrder: "ASC"){
                    locations{
                      _id
                      title
                    }
                  }
              }
            `,
    };

    sendRequest(
      graphqlQuery,
      'GET_LOCATIONS',
    );
  }, [sendRequest]);

  const getManufacturers = useCallback(() => {
    const graphqlQuery = {
      query: `
        query Manufacturers {
          manufacturers(sortField: "title", sortOrder: "ASC") {
            manufacturers {
              _id
              title
            }
          }
        }
      `,
    };

    sendRequest(
      graphqlQuery,
      'GET_MANUFACTURES',
    );
  }, [sendRequest]);

  const getUnitOfMeasurements = useCallback(() => {
    const graphqlQuery = {
      query: `
              query UnitOfMeasurements {
                unitOfMeasurements (sortField: "createdAt", sortOrder: "ASC"){
                    unitOfMeasurements {
                      _id
                      singular
                    }
                  }
              }
            `,
    };

    sendRequest(
      graphqlQuery,
      'GET_UNITOFMEASUREMENTS',
    );
  }, [sendRequest]);

  const getProduct = useCallback(() => {
    const graphqlQuery = {
      query: `
          query Product ($productId: ID!){
            product(id: $productId){
                _id
                title
                description
                option
                details
                oldPrice
                price
                weight
                width
                height
                depth
                deadline
                image
                imageThumbnail
                images
                imagesThumbnail
                checkShipping
                isPromotion
                isFeatured
                isActivated
                keywords {
                    _id
                    title
                }
                category {
                    _id
                    title
                }
                location {
                    _id
                    title
                }
                manufacturer {
                    _id
                    title
                }
                unitOfMeasurement {
                    _id
                    singular
                }
            }
          }
        `,
      variables: {
        productId: id
      }
    };

    sendRequest(
      graphqlQuery,
      'GET_PRODUCT',
    );
  }, [id, sendRequest]);

  useEffect(() => {
    if (id !== 'new') {
      getProduct();
    }
    getKeywords();
    getCategories();
    getLocations();
    getManufacturers();
    getUnitOfMeasurements();

  }, [id, getKeywords, getCategories, getLocations, getManufacturers, getUnitOfMeasurements, getProduct, sendRequest]);

  useEffect(() => {
    if (data) {
      if (data.product) {
        setTitle(data.product.title);
        setImageList(data.product.imagesThumbnail);
        const args = [];
        args.title = data.product.title;
        args.description = data.product.description;
        args.option = data.product.option;
        args.details = data.product.details;
        args.oldPrice = data.product.oldPrice;
        args.price = data.product.price;
        args.weight = data.product.weight;
        args.width = data.product.width;
        args.height = data.product.height;
        args.depth = data.product.depth;
        args.deadline = data.product.deadline;
        setMainImage(data.product.imageThumbnail);
        setCategory(data.product.category._id);
        setLocation(data.product.location._id);
        setManufacturer(data.product.manufacturer._id);
        setUnitOfMeasurement(data.product.unitOfMeasurement._id);
        args.checkShipping = data.product.checkShipping;
        args.isPromotion = data.product.isPromotion;
        args.isFeatured = data.product.isFeatured;
        args.isActivated = data.product.isActivated;
        args.category = data.product.category.title;
        args.location = data.product.location.title;
        args.manufacturer = data.product.manufacturer.title;
        args.unitOfMeasurement = data.product.unitOfMeasurement.singular;
        onFillForm(args);

        const targetKeysKeywords = [];
        const keywords = data.product.keywords;
        for (let i = 0; i < keywords.length; i++) {
          targetKeysKeywords.push(keywords[i]._id);
        }
        setTargetKeywords(targetKeysKeywords);
      }
      if (data.updateProduct) {
        notification.open({
          message: 'Registro atualizado com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/products/');
      }
      if (data.createProduct) {
        notification.open({
          message: 'Registro inserido com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/products/');
      }
      if (data.keywords) {
        const keywords = data.keywords.keywords.map(item => {
          return {
            ...item,
            key: item._id
          }
        });
        setKeywords(keywords);
      }
      if (data.categories) {
        const categories = data.categories.categories.map(item => {
          return {
            ...item,
            key: item._id
          }
        });
        setCategories(categories);
      }
      if (data.locations) {
        const locations = data.locations.locations.map(item => {
          return {
            ...item,
            key: item._id
          }
        });
        setLocations(locations);
      }
      if (data.manufacturers) {
        const manufacturers = data.manufacturers.manufacturers.map(item => {
          return {
            ...item,
            key: item._id
          }
        });
        setManufacturers(manufacturers);
      }
      if (data.unitOfMeasurements) {
        const unitOfMeasurements = data.unitOfMeasurements.unitOfMeasurements.map(item => {
          return {
            ...item,
            key: item._id
          }
        });
        setUnitOfMeasurements(unitOfMeasurements);
      }
      if (data.createProductKeywordMultiple) {
        getProduct();
        if (data.createProductKeywordMultiple > 0) {
          notification.open({
            message: data.createProductKeywordMultiple === 1 ?
              'Uma palavra-chave foi vinculada com sucesso!' :
              `${data.createProductKeywordMultiple} palavras-chave foram vinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
      }
      if (data.deleteProductKeywordMultiple) {
        getProduct();
        if (data.deleteProductKeywordMultiple > 0) {
          notification.open({
            message: data.deleteProductKeywordMultiple === 1 ?
              'Uma palavra-chave foi desvinculada com sucesso!' :
              `${data.deleteProductKeywordMultiple} palavras-chave foram desvinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
      }
      if (data.storageProductImage) {
        getProduct();
        notification.open({
          message: `Imagem principal atualizada com sucesso!`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
      if (data.storageProductImages) {
        let status = data.storageProductImages.status;
        if (status.includes('file(s) successfully uploaded!')) {
          status = status.replace('file(s) successfully uploaded!', 'arquivo(s) carregado(s) com sucesso!');
          getProduct();
        }
        notification.open({
          message: `${status}`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
      if (data.deleteProductImage) {
        getProduct();
        setVisibleDelete(!visibleDelete);
        notification.open({
          message: `Imagem excluída com sucesso!`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getKeywords, getProduct, history]);

  useEffect(() => {
    if (error) {
      if (error[0].status === 401) {
        history.replace('/');
        localStorage.clear();
        notification.open({
          message: 'Não Autorizado!',
          description:
            'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
          icon: <LockOutlined style={{ color: '#B22222' }} />,
          onClick: () => {
            history.replace('/');
            localStorage.clear();
          },
          duration: 1
        });
      }
      if (error[0].status === 422) {
        const valErrors = [];
        for (let i = 0; i < error[0].data.length; i++) {
          let message = error[0].data[i].message;
          if (message.includes('Description must be 120 to 160 characters.'))
            message = 'Descrição deve ter entre 120 e 160 caracteres.';
          valErrors.push(message);
        }
        if (validationErrors === undefined) {
          setValidationErrors(valErrors + '\n');
        } else {
          setValidationErrors(validationErrors + valErrors + '\n');
        }
      }
      if (error[0].status === 11000) {
        let err;
        if (error[0].message.includes('duplicate key error collection') === true) {
          err = 'Título ou Descrição duplicados.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 404) {
        let err;
        if (error[0].message.includes('No product found!') === true) {
          err = 'Produto não encontrado.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 500) {
        if (id !== 'new') {
          if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
            notification.open({
              message: 'Produto não encontrado',
              description:
                'Você foi direcionado para a página de Produtos',
              icon: <BarcodeOutlined style={{ color: '#B22222' }} />,
              onClick: () => {
                history.replace('/products/');
              },
              duration: 3
            });
            history.replace('/products/');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  let draggerUpload = null;
  if (imageList.length < 12) {
    draggerUpload = (
      <Dragger {...propsUploadImages}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
      </Dragger>
    );
  }

  let image = null;
  if (id !== 'new') {
    image = (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h2>Imagem principal</h2>
          <Row gutter={16}>
            <Col span={8}></Col>
            <Col span={8}>
              <Card
                hoverable
                cover={<img title={title} alt={title} src={mainImage} />}
              >
                <Dragger {...propsUploadImage}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </Dragger>
              </Card>
            </Col>
            <Col span={8}></Col>
          </Row>
          <h2>Imagens secundárias ({imageList.length} de 12)</h2>
          {draggerUpload}
        </div>
        <div className="site-card-wrapper">
          <Row gutter={16}>
            {imageList.map(image => (
              <Col key={image} span={8}>
                <Card onClick={() => selectToDelete(image)} style={{ marginTop: '15px' }}
                  hoverable
                  cover={<img title={title} alt={title} src={image} />}
                >
                  <p style={{ textAlign: 'center' }}>
                    <DeleteFilled />
                  </p>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <Modal
          title="Exclusão de registro(s)"
          visible={visibleDelete}
          onOk={deleteData}
          onCancel={deleteCancel}
          okText='Sim'
          cancelText='Não'
        >
          <p>Confirma a exclusão do(s) registro(s) selecionado(s)?</p>
        </Modal>
      </div>
    );
  }

  let showKeywords = null;
  if (id !== 'new') {
    showKeywords = (
      <Form.Item label="Palavras-chave">
        <Transfer
          dataSource={keywords}
          listStyle={{
            height: 400,
          }}
          showSearch
          filterOption={filterOption}
          targetKeys={targetKeywords}
          onChange={handleKeywordsChange}
          render={item => item.title}
        />
      </Form.Item>
    );
  }

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => history.replace('/products/')}
        title="Produtos"
        subTitle={title === null ? 'Novo registro' : title}></PageHeader>
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Informações gerais" key="1">
            <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
              <Form.Item label="Título" name="title"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Título!'
                }]}>
                <Input />
                {/* <Input disabled={id === 'new' ? false : true} /> */}
              </Form.Item>
              <Form.Item label="Descrição" name="description"
                rules={[{
                  required: true,
                  message: 'Favor preencher a Descrição!'
                }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Opção" name="option"
                rules={[{
                  required: true,
                  message: 'Favor preencher a Opção!'
                }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Detalhes" name="details"
                rules={[{
                  required: true,
                  message: 'Favor preencher os Detalhes!'
                }]}>
                <TextArea rows={5} />
              </Form.Item>
              <Form.Item label="Preço antigo" name="oldPrice"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Preço antigo!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Preço" name="price"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Preço!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Peso" name="weight"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Peso!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Largura" name="width"
                rules={[{
                  required: true,
                  message: 'Favor preencher a Largura!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Altura" name="height"
                rules={[{
                  required: true,
                  message: 'Favor preencher a Altura!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Profundidade" name="depth"
                rules={[{
                  required: true,
                  message: 'Favor preencher a Profundidade!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Prazo de entrega" name="deadline"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Prazo de entrega!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Categoria" name="category"
                rules={[{
                  required: true,
                  message: 'Favor selecionar a Categoria!'
                }]}>
                <Select
                  placeholder="Selecione a categoria"
                  onChange={handleChangeCategory}
                  allowClear
                >
                  {categories.map(category => (
                    <Option key={category._id} value={category._id}>{category.title}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Localização" name="location"
                rules={[{
                  required: true,
                  message: 'Favor selecionar a Localização!'
                }]}>
                <Select
                  placeholder="Selecione a localização"
                  onChange={handleChangeLocation}
                  allowClear
                >
                  {locations.map(location => (
                    <Option key={location._id} value={location._id}>{location.title}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Fabricante" name="manufacturer"
                rules={[{
                  required: true,
                  message: 'Favor selecionar o Fabricante!'
                }]}>
                <Select
                  placeholder="Selecione o fabricante"
                  onChange={handleChangeManufacturer}
                  allowClear
                >
                  {manufacturers.map(manufacturer => (
                    <Option key={manufacturer._id} value={manufacturer._id}>{manufacturer.title}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Unidade de medida" name="unitOfMeasurement"
                rules={[{
                  required: true,
                  message: 'Favor selecionar a Unidade de medida!'
                }]}>
                <Select
                  placeholder="Selecione a unidade de medida"
                  onChange={handleChangeUnitOfMeasurement}
                  allowClear
                >
                  {unitOfMeasurements.map(unitOfMeasurement => (
                    <Option key={unitOfMeasurement._id} value={unitOfMeasurement._id}>{unitOfMeasurement.singular}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Checar envio">
                <Switch checked={checkShipping} onChange={onCheckShippingChange} />
              </Form.Item>
              <Form.Item label="Promoção">
                <Switch checked={isPromotion} onChange={onIsPromotionChange} />
              </Form.Item>
              <Form.Item label="Destaque">
                <Switch checked={isFeatured} onChange={onIsFeaturedChange} />
              </Form.Item>
              <Form.Item label="Ativado">
                <Switch checked={isActivated} onChange={onIsActivatedChange} />
              </Form.Item>
              {
                validationErrors !== '' ?
                  <Form.Item {...tailFormItemLayout}>
                    <Alert
                      message="Verifique os seguintes erros"
                      description={validationErrors}
                      type="error"
                      showIcon
                    />
                  </Form.Item> : null
              }
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Salvar
                            </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Imagens" key="2" disabled={id === 'new' ? true : false}>
            <Form {...formItemLayout}>
              {image}
            </Form>
          </TabPane>
          <TabPane tab="Palavras-chave" key="4" disabled={id === 'new' ? true : false}>
            <Form {...formItemLayout}>
              {showKeywords}
            </Form>
          </TabPane>
        </Tabs>
      </Content>
      <Footer />
    </Layout >
  );
}

export default Product;