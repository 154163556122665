import React from 'react';
import { Layout } from 'antd';

import Footer from '../Footer/Footer';

const { Content } = Layout;

const master = props => (
    <Layout>
        {/* <Header /> */}
        <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                {props.children}
            </div>
        </Content>
        <Footer />
    </Layout>
);

export default master;