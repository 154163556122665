import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageHeader, Layout, Form, Input, Button, notification, Alert } from 'antd';
import { CheckOutlined, LockOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;

const UnitOfMeasurement = () => {

    const { isLoading, error, data, sendRequest } = useHttp();

    const match = useRouteMatch('/unitOfMeasurements/:id');
    const history = useHistory();
    const [id] = useState(match.params.id);
    const [validationErrors, setValidationErrors] = useState('');
    const [singular, setSingular] = useState('');

    const formRef = useRef();

    const handleSubmit = values => {
        setValidationErrors('');
        if (id !== 'new') {
            const graphqlQuery = {
                query: `
                    mutation UpdateUnitOfMeasurement ($unitOfMeasurementId: ID!, $singular: String!, $plural: String!){
                        updateUnitOfMeasurement(id: $unitOfMeasurementId,
                            unitOfMeasurementInput:{
                                singular: $singular
                                plural: $plural
                            }
                        )
                        {
                            _id
                            singular
                            plural
                        }
                    }
                    `,
                variables: {
                    unitOfMeasurementId: id,
                    singular: values.singular,
                    plural: values.plural
                }
            };
            sendRequest(
                graphqlQuery,
                'UPDATE_KEYWORD',
            );
        }
        else {
            const graphqlQuery = {
                query: `
                    mutation createUnitOfMeasurement ($singular: String!, $plural: String!){
                        createUnitOfMeasurement(
                            unitOfMeasurementInput:{
                                singular: $singular,
                                plural: $plural
                            }
                        )
                        {
                            _id
                            singular
                            plural
                        }
                    }
                    `,
                variables: {
                    singular: values.singular,
                    plural: values.plural
                }
            };
            sendRequest(
                graphqlQuery,
                'UPDATE_KEYWORD',
            );
        }
    };

    const onFillForm = args => {
        formRef.current.setFieldsValue({
            singular: args.singular,
            plural: args.plural
        });
    };

    useEffect(() => {
        if (id !== 'new') {
            const graphqlQuery = {
                query: `
              query UnitOfMeasurement ($unitOfMeasurementId: ID!){
                unitOfMeasurement(id: $unitOfMeasurementId){
                    _id
                    singular
                    plural
                }
              }
            `,
                variables: {
                    unitOfMeasurementId: id
                }
            };

            sendRequest(
                graphqlQuery,
                'GET_KEYWORD',
            );
        }
    }, [id, sendRequest]);

    useEffect(() => {
        if (data) {
            if (data.unitOfMeasurement) {
                setSingular(data.unitOfMeasurement.singular);
                const args = [];
                args.singular = data.unitOfMeasurement.singular;
                args.plural = data.unitOfMeasurement.plural;
                onFillForm(args);
            }
            if (data.updateUnitOfMeasurement) {
                notification.open({
                    message: 'Registro atualizado com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/unitOfMeasurements/');
            }
            if (data.createUnitOfMeasurement) {
                notification.open({
                    message: 'Registro inserido com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/unitOfMeasurements/');
            }
        }
    }, [data, history]);

    useEffect(() => {
        if (error) {
            if (error[0].status === 401) {
                history.replace('/');
                localStorage.clear();
                notification.open({
                    message: 'Não Autorizado!',
                    description:
                        'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
                    icon: <LockOutlined style={{ color: '#B22222' }} />,
                    onClick: () => {
                        history.replace('/');
                        localStorage.clear();
                    },
                    duration: 1
                });
            }
            if (error[0].status === 422) {
                const valErrors = [];
                for (let i = 0; i < error[0].data.length; i++) {
                    let message = error[0].data[i].message;
                    if (message.includes('Description must be 120 to 160 characters.'))
                        message = 'Descrição deve ter entre 120 e 160 caracteres.';
                    valErrors.push(message);
                }
                if (validationErrors === undefined) {
                    setValidationErrors(valErrors + '\n');
                } else {
                    setValidationErrors(validationErrors + valErrors + '\n');
                }
            }
            if (error[0].status === 11000) {
                let err;
                if (error[0].message.includes('duplicate key error collection') === true) {
                    err = 'Título duplicado.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 404) {
                let err;
                if (error[0].message.includes('No unitOfMeasurement found!') === true) {
                    err = 'Palavra-chave não encontrada.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 500) {
                if (id !== 'new') {
                    if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
                        notification.open({
                            message: 'Página não encontrada',
                            description:
                                'Você foi direcionado para Páginas',
                            icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
                            duration: 3
                        });
                        history.replace('/unitOfMeasurements/');
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <Layout>
            <PageHeader
                ghost={false}
                onBack={() => history.replace('/unitOfMeasurements/')}
                title="Unidades de medida"
                subTitle={singular === null ? 'Novo registro' : singular}></PageHeader>
            <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
                <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
                    <Form.Item label="Singular" name="singular"
                        rules={[{
                            required: true,
                            message: 'Favor preencher o Singular!'
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Plural" name="plural"
                        rules={[{
                            required: true,
                            message: 'Favor preencher o Plural!'
                        }]}>
                        <Input />
                    </Form.Item>
                    {
                        validationErrors !== '' ?
                            <Form.Item {...tailFormItemLayout}>
                                <Alert
                                    message="Verifique os seguintes erros"
                                    description={validationErrors}
                                    type="error"
                                    showIcon
                                />
                            </Form.Item> : null
                    }
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Salvar
                            </Button>
                    </Form.Item>
                </Form>
            </Content>
            <Footer />
        </Layout>
    );
}

export default UnitOfMeasurement;