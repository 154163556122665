import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  PageHeader, Layout, Form, Input, Button,
  notification, Alert, Transfer, Tabs
} from 'antd';
import {
  CheckOutlined, LockOutlined, BarcodeOutlined
} from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;
const { TabPane } = Tabs;


const Departament = () => {

  const { isLoading, error, data, sendRequest } = useHttp();

  const match = useRouteMatch('/departaments/:id');
  const history = useHistory();
  const [id] = useState(match.params.id);
  const [validationErrors, setValidationErrors] = useState('');
  const [title, setTitle] = useState('');
  const [categories, setCategories] = useState([]);

  const formRef = useRef();

  const [targetCategories, setTargetCategories] = useState([]);

  const handleSubmit = values => {
    setValidationErrors('');
    if (id !== 'new') {
      // UPDATE
      const graphqlQuery = {
        query: `
                                mutation UpdateDepartament ($departamentId: ID!, 
                                    $title: String!){
                                    updateDepartament(id: $departamentId,
                                        departamentInput:{
                                            title: $title,
                                        }
                                    )
                                    {
                                        _id
                                        title
                                    }
                                }
                                `,
        variables: {
          departamentId: id,
          title: values.title,
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_DEPARTAMENT',
      );
    }
    else {
      const graphqlQuery = {
        query: `
                    mutation createDepartament (
                        $title: String!){
                        createDepartament(
                            departamentInput:{
                                title: $title
                            }
                        )
                        {
                            _id
                            title
                        }
                    }
                `,
        variables: {
          departamentId: id,
          title: values.title
        }
      };
      sendRequest(
        graphqlQuery,
        'CREATE_DEPARTAMENT',
      );
    }
  };

  const onFillForm = args => {
    formRef.current.setFieldsValue({
      title: args.title
    });
  };

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const handleCategoriesChange = (targetKeys, direction, moveKeys) => {
    if (direction === 'left') {
      // DELETE
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{departamentId: "${id}", categoryId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
                    mutation { deleteDepartamentCategoryMultiple(departamentCategoryInput: 
                            [
                            ${array}
                            ]
                        )
                    }
                    `
      };
      sendRequest(
        graphqlQuery,
        'DELETE_DEPARTAMENT_CATEGORY',
      );
    }
    else if (direction === 'right') {
      // INSERT
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{departamentId: "${id}", categoryId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
          mutation { createDepartamentCategoryMultiple(departamentCategoryInput: 
              [
                ${array}
              ]
            )
          }
        `
      };
      sendRequest(
        graphqlQuery,
        'CREATE_DEPARTAMENT_CATEGORY',
      );
    }
  };

  const getCategories = useCallback(() => {
    const graphqlQuery = {
      query: `
              query Categories {
                categories (sortField: "title", sortOrder: "ASC"){
                    categories{
                      _id
                      title
                    }
                  }
              }
            `,
    };

    sendRequest(
      graphqlQuery,
      'GET_CATEGORIES',
    );
  }, [sendRequest]);

  const getDepartament = useCallback(() => {
    const graphqlQuery = {
      query: `
          query Departament ($departamentId: ID!){
            departament(id: $departamentId){
                _id
                title
                categories {
                    _id
                    title
                }
            }
          }
        `,
      variables: {
        departamentId: id
      }
    };

    sendRequest(
      graphqlQuery,
      'GET_DEPARTAMENT',
    );
  }, [id, sendRequest]);

  useEffect(() => {
    if (id !== 'new') {
      getDepartament();
    }
    getCategories();

  }, [id, getCategories, getDepartament, sendRequest]);

  useEffect(() => {
    if (data) {
      if (data.departament) {
        setTitle(data.departament.title);
        const args = [];
        args.title = data.departament.title;
        onFillForm(args);

        const targetKeysCategories = [];
        const categories = data.departament.categories;
        for (let i = 0; i < categories.length; i++) {
          targetKeysCategories.push(categories[i]._id);
        }
        setTargetCategories(targetKeysCategories);
      }
      if (data.updateDepartament) {
        notification.open({
          message: 'Registro atualizado com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/departaments/');
      }
      if (data.createDepartament) {
        notification.open({
          message: 'Registro inserido com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/departaments/');
      }
      if (data.categories) {
        const categories = data.categories.categories.map(item => {
          return {
            ...item,
            key: item._id
          }
        });
        setCategories(categories);
      }
      if (data.createDepartamentCategoryMultiple) {
        getDepartament();
        if (data.createDepartamentCategoryMultiple > 0) {
          notification.open({
            message: data.createDepartamentCategoryMultiple === 1 ?
              'Uma categoria foi vinculada com sucesso!' :
              `${data.createDepartamentCategoryMultiple} categorias foram vinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
      }
      if (data.deleteDepartamentCategoryMultiple) {
        getDepartament();
        if (data.deleteDepartamentCategoryMultiple > 0) {
          notification.open({
            message: data.deleteDepartamentCategoryMultiple === 1 ?
              'Uma categoria foi desvinculada com sucesso!' :
              `${data.deleteDepartamentCategoryMultiple} categorias foram desvinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getCategories, getDepartament, history]);

  useEffect(() => {
    if (error) {
      if (error[0].status === 401) {
        history.replace('/');
        localStorage.clear();
        notification.open({
          message: 'Não Autorizado!',
          description:
            'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
          icon: <LockOutlined style={{ color: '#B22222' }} />,
          onClick: () => {
            history.replace('/');
            localStorage.clear();
          },
          duration: 1
        });
      }
      if (error[0].status === 422) {
        const valErrors = [];
        for (let i = 0; i < error[0].data.length; i++) {
          let message = error[0].data[i].message;
          if (message.includes('Description must be 120 to 160 characters.'))
            message = 'Descrição deve ter entre 120 e 160 caracteres.';
          valErrors.push(message);
        }
        if (validationErrors === undefined) {
          setValidationErrors(valErrors + '\n');
        } else {
          setValidationErrors(validationErrors + valErrors + '\n');
        }
      }
      if (error[0].status === 11000) {
        let err;
        if (error[0].message.includes('duplicate key error collection') === true) {
          err = 'Título ou Descrição duplicados.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 404) {
        let err;
        if (error[0].message.includes('No departament found!') === true) {
          err = 'Departamento não encontrado.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 500) {
        if (id !== 'new') {
          if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
            notification.open({
              message: 'Departamento não encontrado',
              description:
                'Você foi direcionado para a página de Departamentos',
              icon: <BarcodeOutlined style={{ color: '#B22222' }} />,
              onClick: () => {
                history.replace('/departaments/');
              },
              duration: 3
            });
            history.replace('/departaments/');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  let showCategories = null;
  if (id !== 'new') {
    showCategories = (
      <div>
        <Form.Item label="Categorias">
          <Transfer
            dataSource={categories}
            listStyle={{
              height: 400,
            }}
            showSearch
            filterOption={filterOption}
            targetKeys={targetCategories}
            onChange={handleCategoriesChange}
            render={item => item.title}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} onClick={() => getDepartament()}>
            Reload
                    </Button>
        </Form.Item>
      </div>
    );
  }

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => history.replace('/departaments/')}
        title="Departamentos"
        subTitle={title === null ? 'Novo registro' : title}></PageHeader>
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Informações gerais" key="1">
            <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
              <Form.Item label="Título" name="title"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Título!'
                }]}>
                <Input disabled={id === 'new' ? false : true} />
              </Form.Item>
              {
                validationErrors !== '' ?
                  <Form.Item {...tailFormItemLayout}>
                    <Alert
                      message="Verifique os seguintes erros"
                      description={validationErrors}
                      type="error"
                      showIcon
                    />
                  </Form.Item> : null
              }
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Salvar
                            </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Categorias" key="3" disabled={id === 'new' ? true : false}>
            <Form {...formItemLayout}>
              {showCategories}
            </Form>
          </TabPane>
        </Tabs>
      </Content>
      <Footer />
    </Layout >
  );
}

export default Departament;