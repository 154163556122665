import React, { useState, useEffect, useCallback, Space } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PageHeader, Layout, Table, Button, notification, Input, Modal } from 'antd';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import useHttp from '../../hooks/http';
import Footer from '../../components/Footer/Footer';

const { Content } = Layout;

const Contacts = () => {

    const { isLoading, error, data, sendRequest } = useHttp();

    const [pagination, setPagination] = useState({});
    const [searchText, setSearchText] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [visibleRemovedAt, setVisibleRemovedAt] = useState(false);
    const [getData, setData] = useState([]);
    const [searchInput, setSearchInput] = useState(null);
    const [modalErrorVisible, setModalErrorVisible] = useState(false);
    const [modalErrorTitle, setModalErrorTitle] = useState('');
    const [modalErrorMessage, setModalErrorMessage] = useState('');
    const [modalErrorOkText, setModalErrorOkText] = useState('');

    const history = useHistory();

    const modalDelete = () => {
        setVisibleDelete(true)
    };

    const modalRemovedAt = () => {
        setVisibleRemovedAt(true);
    };

    const deleteData = e => {
        setVisibleDelete(false);
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const graphqlQuery = {
                query: `
                    mutation DeleteContact($id: ID!) {
                        deleteContact(id: $id)
                    }
                `,
                variables: {
                    id: selectedRowKeys[i],
                }
            };
            sendRequest(
                graphqlQuery,
                'DELETE_CONTACT',
            );
        }
    };

    const deleteCancel = e => {
        setVisibleDelete(false);
    };

    const changeRemovedAt = e => {
        setVisibleRemovedAt(false);
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const graphqlQuery = {
                query: `
                    mutation UpdateRemovedAtContact($id: ID!) {
                        updateRemovedAtContact(id: $id){
                            _id
                            name
                            email
                            removedAt
                        }
                    }
                `,
                variables: {
                    id: selectedRowKeys[i],
                }
            };
            sendRequest(
                graphqlQuery,
                'CHANGE_REMOVED_AT_CONTACT',
            );
        }

    };

    const changeRemovedAtCancel = e => {
        console.log(e);
        setVisibleRemovedAt(false);
    };

    const changeError = e => {

        switch (modalErrorOkText) {
            case 'Realizar login como Administrador':
                history.replace('/');
                localStorage.clear();
                break;
            default:
                setTimeout(() => {
                    setPagination({});
                    setSearchText('');
                    setSelectedRowKeys([]);
                    setData([]);
                    setSearchInput(null);
                    fetch();
                }, 500);
        }
        setModalErrorVisible(false);
    };

    const changeErrorCancel = e => {
        setModalErrorVisible(false);
    };

    const onSelectToDelete = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        setSearchInput(node);
                    }}
                    placeholder='Pesquisar'
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 208, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 110, marginRight: 8 }}
                >
                    Pesquisar
            </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reiniciar
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visibleDelete => {
            if (visibleDelete) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const fetch = useCallback((params = {}) => {
        let sortField = params.sortField;
        if (sortField === undefined)
            sortField = 'updatedAt';
        let sortOrder = params.sortOrder;
        if (sortOrder === undefined)
            sortOrder = 'DESC';
        else if (sortOrder === 'ascend')
            sortOrder = 'ASC';
        else if (sortOrder === 'descend')
            sortOrder = 'DESC';
        const graphqlQuery = {
            query: `
              query Contacts($filter: String, $sortField: String, $sortOrder: String, $page: Int, $pageSize: Int) {
                contacts(filter: $filter, sortField: $sortField, sortOrder: $sortOrder, page: $page, pageSize: $pageSize){
                    contacts{
                      _id
                      name
                      email
                      removedAt
                      createdAt
                      updatedAt
                    }
                    totalRecords
                  }
              }
            `,
            variables: {
                filter: searchText,
                sortField,
                sortOrder,
                page: params.page,
                pageSize: params.results === undefined ? 10 : params.results
            }
        };

        sendRequest(
            graphqlQuery,
            'GET_CONTACTS',
        );
    }, [searchText, sendRequest]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        if (data) {
            if (data.contacts) {
                const _pagination = { ...pagination };
                _pagination.total = data.contacts.totalRecords;
                const initialData = data.contacts.contacts;
                const _data = initialData.map(item => {
                    return {
                        ...item,
                        removedAt: item.removedAt ? new Date(item.removedAt).toLocaleDateString('pt-BR') : `[não removido]`,
                        createdAt: new Date(item.createdAt).toLocaleDateString('pt-BR'),
                        updatedAt: new Date(item.updatedAt).toLocaleDateString('pt-BR')
                    }
                });
                setPagination(_pagination);
                setData(_data);

            }
            if (data.updateRemovedAtContact) {
                fetch();
                notification.open({
                    message: 'Registros atualizados com sucesso!',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                setTimeout(() => {
                    setSelectedRowKeys([]);
                }, 500);
            }
            if (data.deleteContact) {
                if (data.deleteContact === true) {
                    fetch();
                    notification.open({
                        message: 'Registros excluídos com sucesso!',
                        icon: <CheckOutlined style={{ color: '#008000' }} />,
                        duration: 2
                    });
                    setTimeout(() => {
                        setSelectedRowKeys([]);
                    }, 500);
                }
                else {
                    setModalErrorVisible(true);
                    setModalErrorTitle('Erro ao excluir registros(s)!');
                    setModalErrorMessage('Houve um erro desconhecido ao excluir um ou mais registros.');
                    setModalErrorOkText('Ok');
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, fetch]);

    useEffect(() => {
        if (error) {
            try {
                if (error[0].status) {
                    if (error[0].status === 404 || error[0].status === 401) {
                        history.replace('/');
                        localStorage.clear();
                        setModalErrorVisible(true);
                        setModalErrorTitle('Não Autorizado!');
                        setModalErrorMessage('Você não tem autorização para acessar esta área.');
                        setModalErrorOkText('Realizar login como Administrador');
                    }
                    else {
                        setModalErrorVisible(true);
                        setModalErrorTitle('Erro');
                        setModalErrorMessage(error[0].message);
                        setModalErrorOkText('Ok');
                    }
                }
            }
            catch {
                setModalErrorVisible(true);
                setModalErrorTitle('Erro');
                setModalErrorMessage('Erro ao acessar servidor, clique em OK para tentar novamente.');
                setModalErrorOkText('Ok');
            }
        }
    }, [error, history]);

    const handleTableChange = (_pagination, filters, sorter) => {
        const pager = { pagination };
        pager.current = _pagination.current;
        setPagination(pager);
        if (!filters.name) {
            fetch({
                results: _pagination.pageSize,
                page: _pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectToDelete,
        hideDefaultSelections: true
    };
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: 'Ações',
            dataIndex: '_id',
            key: '_id',
            render: text => <Link to={'/contacts/' + text} key={text}>Editar</Link>,
            width: 80,
            fixed: 'left'
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: 'Removido em',
            dataIndex: 'removedAt',
            key: 'removedAt',
            sorter: true,
            width: 140
        },
        {
            title: 'Criação',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            width: 130
        },
        {
            title: 'Alteração',
            dataIndex: 'updatedAt',
            defaultSortOrder: 'descend',
            key: 'updatedAt',
            sorter: true,
            width: 130
        }
    ];

    return (
        <Layout>
            <PageHeader title="Contatos"></PageHeader>
            <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
                <div>
                    <div style={{ marginBottom: 16 }}>
                        <Space>
                            <Button type="primary" onClick={modalDelete} disabled={!hasSelected} loading={isLoading}>
                                Excluir
                            </Button>
                            <Button type="primary" onClick={modalRemovedAt} disabled={!hasSelected} loading={isLoading}>
                                Alterar status de remoção
                            </Button>
                        </Space>
                        <Modal
                            title="Exclusão de registro(s)"
                            visible={visibleDelete}
                            onOk={deleteData}
                            onCancel={deleteCancel}
                            okText='Sim'
                            cancelText='Não'
                        >
                            <p>Confirma a exclusão do(s) registro(s) selecionado(s)?</p>
                        </Modal>
                        <Modal
                            title="Alterar status de remoção"
                            visible={visibleRemovedAt}
                            onOk={changeRemovedAt}
                            onCancel={changeRemovedAtCancel}
                            okText='Sim'
                            cancelText='Não'
                        >
                            <p>Alterar o status de remoção para o(s) registro(s) selecionado(s)?</p>
                        </Modal>
                        <Modal
                            title={modalErrorTitle}
                            visible={modalErrorVisible}
                            onOk={changeError}
                            onCancel={changeErrorCancel}
                            okText={modalErrorOkText}
                        >
                            <p>{modalErrorMessage}</p>
                        </Modal>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `${selectedRowKeys.length} registro(s) selecionado(s)` : ''}
                        </span>
                    </div>
                    <Table rowSelection={rowSelection} columns={columns} dataSource={getData} pagination={pagination} rowKey={record => record._id} loading={isLoading} onChange={handleTableChange} scroll={{ x: 1300 }} />
                    <div style={{ marginTop: 16 }}>
                        <Button type="primary" onClick={() => history.replace('/contacts/new')}>
                            Novo registro
                    </Button>
                    </div>
                </div>
            </Content>
            <Footer />
        </Layout>
    );
};

export default Contacts;