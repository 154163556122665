import React, { useState, useEffect } from 'react';
import { PageHeader, Form, Input, Button, Layout, notification, Alert } from 'antd';
import { MailOutlined, MailFilled, CheckOutlined } from '@ant-design/icons';

import useHttp from '../../hooks/http';
import Footer from '../../components/Footer/Footer';

const { Content } = Layout;

const ChangePassword = () => {

    const { isLoading, error, data, sendRequest } = useHttp();
    const [validationErrors, setValidationErrors] = useState('');

    const handleSubmit = values => {
        setValidationErrors('');
        const graphqlQuery = {
            query: `
                mutation UpdatePassword ($oldPassword: String!, $newPassword: String!, $confirmNewPassword: String!){
                    updatePassword(
                        changePasswordInput:{
                            oldPassword: $oldPassword
                            newPassword: $newPassword
                            confirmNewPassword: $confirmNewPassword
                        }
                    )
                    {
                        _id
                        email
                    }
                }
            `,
            variables: {
                oldPassword: values.oldPassword.trim(),
                newPassword: values.newPassword.trim(),
                confirmNewPassword: values.confirmNewPassword.trim()
            }
        };
        sendRequest(
            graphqlQuery,
            'CHANGE_PASSWORD',
        );
    };

    useEffect(() => {
        if (data) {
            notification.open({
                message: 'Senha atualizada com sucesso!',
                icon: <CheckOutlined style={{ color: '#008000' }} />,
                duration: 2
            });
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            if (error[0].status === 401) {
                const valErrors = [];
                if (error[0].message === 'Old password is incorrect.') {
                    valErrors.push('A senha atual está errada, favor verificar.');
                }
                else {
                    valErrors.push(error[0].message);
                }
                if (validationErrors === undefined) {
                    setValidationErrors(valErrors + '\n');
                } else {
                    setValidationErrors(validationErrors + valErrors + '\n');
                }
            }
            if (error[0].status === 422) {
                const valErrors = [];
                for (let i = 0; i < error[0].data.length; i++) {
                    let message = error[0].data[i].message;
                    if (message.includes('New password and Confirm new password is not equals.'))
                        message = 'Nova senha e Confirmação da nova nenha estão diferentes, favor verificar.';
                    valErrors.push(message);
                }
                if (validationErrors === undefined) {
                    setValidationErrors(valErrors + '\n');
                } else {
                    setValidationErrors(validationErrors + valErrors + '\n');
                }
            }
            if (error[0].status === 404) {
                const err = error[0].message;

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <Layout>
            <PageHeader ghost={false} title="Login"></PageHeader>
            <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
                <Form {...formItemLayout} onFinish={handleSubmit} className="login-form">
                    <Form.Item label="Senha atual" name="oldPassword"
                        rules={[{
                            required: true,
                            message: 'Entre com sua Senha atual'
                        }]}>
                        <Input
                            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Senha atual"
                        />
                    </Form.Item>
                    <Form.Item label="Nova senha" name="newPassword"
                        rules={[{
                            required: true,
                            message: 'Entre com a Nova senha.'
                        }]}>
                        <Input
                            prefix={<MailFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Nova senha"
                        />
                    </Form.Item>
                    <Form.Item label="Confirmação da nova senha" name="confirmNewPassword"
                        rules={[{
                            required: true,
                            message: 'Entre com a Confirmação da nova senha.'
                        }]}>
                        <Input
                            prefix={<MailFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Confirmação da nova senha"
                        />
                    </Form.Item>
                    {
                        validationErrors !== '' ?
                            <Form.Item {...tailFormItemLayout}>
                                <Alert
                                    message="Verifique os seguintes erros"
                                    description={validationErrors}
                                    type="error"
                                    showIcon
                                />
                            </Form.Item> : null
                    }
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
                            Alterar senha
                            </Button>
                    </Form.Item>
                </Form>
            </Content>
            <Footer />
        </Layout >
    );
}

export default ChangePassword;