import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    PageHeader, Layout, Form, Input, Button, notification, Alert, InputNumber,
    DatePicker, Switch
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import { pt_BR } from 'moment/locale/pt-br';
import { CheckOutlined, LockOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

moment.locale(pt_BR);
const { Content } = Layout;

const DiscountCoupon = () => {

    const { isLoading, error, data, sendRequest } = useHttp();

    const currentDate = new Date();
    const stringBRToDate = dateString => {
        try {

            const splitDate = dateString.split('/');
            return new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]} 23:59:59`);
        }
        catch (error) {
            console.error('Error', error);
        }
    };
    const dateToStringEN = date => {
        console.log('DATE', date);
        return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
    };

    const match = useRouteMatch('/discountcoupons/:id');
    const history = useHistory();
    const [id] = useState(match.params.id);
    const [validationErrors, setValidationErrors] = useState('');
    const [title, setTitle] = useState('');
    const expirationDate = currentDate;
    // expirationDate.setDate(expirationDate.getDate() + 1);
    expirationDate.setHours(9);
    expirationDate.setMinutes(0);
    expirationDate.setSeconds(0);
    expirationDate.setMinutes(0);
    const [expiration, setExpiration] = useState(dateToStringEN(expirationDate));
    const [isActivated, setIsActivated] = useState(true);

    const formRef = useRef();

    const onIsActivatedChange = checked => {
        setIsActivated(checked);
    };

    const onRangePickerChange = (value, dateString) => {
        const expiration = stringBRToDate(dateString);
        setExpiration(dateToStringEN(expiration));
    };

    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    const handleSubmit = values => {
        setValidationErrors('');
        if (id !== 'new') {
            const graphqlQuery = {
                query: `
                                mutation UpdateDiscountCoupon ($discountCouponId: ID!, 
                                    $title: String!, 
                                    $details: String!, 
                                    $expiration: String!, 
                                    $discountPercentage: Int!, 
                                    $minimumPurchase: Float!, 
                                    $isActivated: Boolean!){
                                    updateDiscountCoupon(id: $discountCouponId,
                                        discountCouponInput:{
                                            title: $title,
                                            details: $details, 
                                            expiration: $expiration, 
                                            discountPercentage: $discountPercentage, 
                                            minimumPurchase: $minimumPurchase, 
                                            isActivated: $isActivated
                                        }
                                    )
                                    {
                                        _id
                                        title
                                        details
                                        expiration
                                        discountPercentage
                                        minimumPurchase
                                        isActivated
                                    }
                                }
                                `,
                variables: {
                    discountCouponId: id,
                    title: values.title,
                    details: values.details,
                    expiration,
                    discountPercentage: values.discountPercentage,
                    minimumPurchase: values.minimumPurchase,
                    isActivated
                }
            };
            sendRequest(
                graphqlQuery,
                'UPDATE_DISCOUNTCOUPON',
            );
        }
        else {
            const graphqlQuery = {
                query: `
                                    mutation createDiscountCoupon (
                                        $title: String!, 
                                        $details: String!, 
                                        $expiration: String!, 
                                        $discountPercentage: Int!, 
                                        $minimumPurchase: Float!, 
                                        $isActivated: Boolean!){
                                        createDiscountCoupon(
                                            discountCouponInput:{
                                                title: $title,
                                                details: $details, 
                                                expiration: $expiration, 
                                                discountPercentage: $discountPercentage, 
                                                minimumPurchase: $minimumPurchase, 
                                                isActivated: $isActivated
                                            }
                                        )
                                        {
                                            _id
                                            title
                                            details
                                            expiration
                                            discountPercentage
                                            minimumPurchase
                                            isActivated
                                        }
                                    }
                                `,
                variables: {
                    discountCouponId: id,
                    title: values.title,
                    details: values.details,
                    expiration,
                    discountPercentage: values.discountPercentage,
                    minimumPurchase: values.minimumPurchase,
                    isActivated
                }
            };
            sendRequest(
                graphqlQuery,
                'CREATE_DISCOUNTCOUPON',
            );
        }
    };

    const onFillForm = args => {
        setIsActivated(args.isActivated);
        formRef.current.setFieldsValue({
            title: args.title,
            details: args.details,
            expiration: args.expiration,
            discountPercentage: args.discountPercentage,
            minimumPurchase: args.minimumPurchase
        });
    };

    useEffect(() => {
        if (id !== 'new') {
            const graphqlQuery = {
                query: `
              query DiscountCoupon ($discountCouponId: ID!){
                discountCoupon(id: $discountCouponId){
                    _id
                    title
                    details
                    expiration
                    discountPercentage
                    minimumPurchase
                    isActivated
                }
              }
            `,
                variables: {
                    discountCouponId: id
                }
            };
            sendRequest(
                graphqlQuery,
                'GET_DISCOUNTCOUPON',
            );
        }
    }, [id, sendRequest]);

    useEffect(() => {
        if (data) {
            if (data.discountCoupon) {
                setTitle(data.discountCoupon.title);
                const expiration = new Date(data.discountCoupon.expiration);
                setExpiration(dateToStringEN(expiration));
                const args = [];
                args.title = data.discountCoupon.title;
                args.details = data.discountCoupon.details;
                args.expiration = dateToStringEN(expiration);
                args.discountPercentage = data.discountCoupon.discountPercentage;
                args.minimumPurchase = data.discountCoupon.minimumPurchase;
                args.isActivated = data.discountCoupon.isActivated;
                onFillForm(args);
            }
            if (data.updateDiscountCoupon) {
                notification.open({
                    message: 'Registro atualizado com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/discountcoupons/');
            }
            if (data.createDiscountCoupon) {
                notification.open({
                    message: 'Registro inserido com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/discountcoupons/');
            }
        }
    }, [data, history]);

    useEffect(() => {
        if (error) {
            if (error[0].status === 401) {
                history.replace('/');
                localStorage.clear();
                notification.open({
                    message: 'Não Autorizado!',
                    description:
                        'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
                    icon: <LockOutlined style={{ color: '#B22222' }} />,
                    onClick: () => {
                        history.replace('/');
                        localStorage.clear();
                    },
                    duration: 1
                });
            }
            if (error[0].status === 422) {
                const valErrors = [];
                for (let i = 0; i < error[0].data.length; i++) {
                    let message = error[0].data[i].message;
                    if (message.includes('Description must be 120 to 160 characters.'))
                        message = 'Descrição deve ter entre 120 e 160 caracteres.';
                    valErrors.push(message);
                }
                if (validationErrors === undefined) {
                    setValidationErrors(valErrors + '\n');
                } else {
                    setValidationErrors(validationErrors + valErrors + '\n');
                }
            }
            if (error[0].status === 11000) {
                let err;
                if (error[0].message.includes('duplicate key error collection') === true) {
                    err = 'Título duplicado.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 404) {
                let err;
                if (error[0].message.includes('No discountCoupon found!') === true) {
                    err = 'Cupom de desconto não encontrado.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 500) {
                if (id !== 'new') {
                    if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
                        notification.open({
                            message: 'Página não encontrada',
                            description:
                                'Você foi direcionado para Cupons de desconto',
                            icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
                            duration: 3
                        });
                        history.replace('/discountcoupons/');
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <Layout>
            <PageHeader
                ghost={false}
                onBack={() => history.replace('/discountCoupons/')}
                title="Palavras-chave"
                subTitle={title === null ? 'Novo registro' : title}></PageHeader>
            <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
                <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
                    <Form.Item label="Título" name="title"
                        rules={[{
                            required: true,
                            message: 'Favor preencher o Título!'
                        }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Detalhes" name="details"
                        rules={[{
                            required: true,
                            message: 'Favor preencher os Detalhes!'
                        }]}>
                        <TextArea rows={5} />
                    </Form.Item>
                    <Form.Item label="Expiração"
                        rules={[{
                            required: true,
                            message: 'Favor preencher a Expiração!'
                        }]}>
                        {/* <RangePicker showTime locale={locale}
                            onChange={onRangePickerChange}
                            value={[moment(start, dateFormat), moment(end, dateFormat)]}
                            format="DD/MM/YYYY HH:mm:ss"
                        /> */}
                        <DatePicker locale={locale}
                            onChange={onRangePickerChange}
                            value={moment(expiration, dateFormat)}
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                    <Form.Item label="Percentual de desconto" name="discountPercentage"
                        rules={[{
                            required: true,
                            message: 'Favor preencher o Percentual de desconto!'
                        }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Compra mínima" name="minimumPurchase"
                        rules={[{
                            required: true,
                            message: 'Favor preencher a Compra mínima!'
                        }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Ativado">
                        <Switch checked={isActivated} onChange={onIsActivatedChange} />
                    </Form.Item>
                    {
                        validationErrors !== '' ?
                            <Form.Item {...tailFormItemLayout}>
                                <Alert
                                    message="Verifique os seguintes erros"
                                    description={validationErrors}
                                    type="error"
                                    showIcon
                                />
                            </Form.Item> : null
                    }
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Salvar
                            </Button>
                    </Form.Item>
                </Form>
            </Content>
            <Footer />
        </Layout>
    );
}

export default DiscountCoupon;