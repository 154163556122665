import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, PageHeader } from 'antd';

import Footer from '../../components/Footer/Footer';

const Logout = () => {

    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('expiryDate');
        localStorage.removeItem('userId');
        history.replace('/');
        localStorage.clear();
    }, [history]);

    return (
        <Layout>
            <PageHeader title="Logout"></PageHeader>
            <Footer />
        </Layout>
    );
}

export default Logout;