import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageHeader, Layout, Form, Input, Button, notification, Alert } from 'antd';
import { CheckOutlined, LockOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;

const Location = () => {

    const { isLoading, error, data, sendRequest } = useHttp();

    const match = useRouteMatch('/locations/:id');
    const history = useHistory();
    const [id] = useState(match.params.id);
    const [validationErrors, setValidationErrors] = useState('');
    const [title, setTitle] = useState('');

    const formRef = useRef();

    const handleSubmit = values => {
        setValidationErrors('');
        if (id !== 'new') {
            const graphqlQuery = {
                query: `
                    mutation UpdateLocation ($locationId: ID!, $title: String!){
                        updateLocation(id: $locationId,
                            locationInput:{
                                title: $title
                            }
                        )
                        {
                            _id
                            title
                        }
                    }
                    `,
                variables: {
                    locationId: id,
                    title: values.title
                }
            };
            sendRequest(
                graphqlQuery,
                'UPDATE_KEYWORD',
            );
        }
        else {
            const graphqlQuery = {
                query: `
                    mutation createLocation ($title: String!){
                        createLocation(
                            locationInput:{
                                title: $title
                            }
                        )
                        {
                            _id
                            title
                        }
                    }
                    `,
                variables: {
                    title: values.title
                }
            };
            sendRequest(
                graphqlQuery,
                'UPDATE_KEYWORD',
            );
        }
    };

    const onFillForm = args => {
        formRef.current.setFieldsValue({
            title: args.title
        });
    };

    useEffect(() => {
        if (id !== 'new') {
            const graphqlQuery = {
                query: `
              query Location ($locationId: ID!){
                location(id: $locationId){
                    _id
                    title
                }
              }
            `,
                variables: {
                    locationId: id
                }
            };

            sendRequest(
                graphqlQuery,
                'GET_KEYWORD',
            );
        }
    }, [id, sendRequest]);

    useEffect(() => {
        if (data) {
            if (data.location) {
                setTitle(data.location.title);
                const args = [];
                args.title = data.location.title;
                onFillForm(args);
            }
            if (data.updateLocation) {
                notification.open({
                    message: 'Registro atualizado com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/locations/');
            }
            if (data.createLocation) {
                notification.open({
                    message: 'Registro inserido com sucesso',
                    icon: <CheckOutlined style={{ color: '#008000' }} />,
                    duration: 2
                });
                history.replace('/locations/');
            }
        }
    }, [data, history]);

    useEffect(() => {
        if (error) {
            if (error[0].status === 401) {
                history.replace('/');
                localStorage.clear();
                notification.open({
                    message: 'Não Autorizado!',
                    description:
                        'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
                    icon: <LockOutlined style={{ color: '#B22222' }} />,
                    onClick: () => {
                        history.replace('/');
                        localStorage.clear();
                    },
                    duration: 1
                });
            }
            if (error[0].status === 422) {
                const valErrors = [];
                for (let i = 0; i < error[0].data.length; i++) {
                    let message = error[0].data[i].message;
                    if (message.includes('Description must be 120 to 160 characters.'))
                        message = 'Descrição deve ter entre 120 e 160 caracteres.';
                    valErrors.push(message);
                }
                if (validationErrors === undefined) {
                    setValidationErrors(valErrors + '\n');
                } else {
                    setValidationErrors(validationErrors + valErrors + '\n');
                }
            }
            if (error[0].status === 11000) {
                let err;
                if (error[0].message.includes('duplicate key error collection') === true) {
                    err = 'Título duplicado.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 404) {
                let err;
                if (error[0].message.includes('No location found!') === true) {
                    err = 'Palavra-chave não encontrada.';
                }
                else {
                    err = error[0].message;
                }

                if (validationErrors === undefined) {
                    setValidationErrors(err + '\n');
                } else {
                    setValidationErrors(validationErrors + err + '\n');
                }
            }
            if (error[0].status === 500) {
                if (id !== 'new') {
                    if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
                        notification.open({
                            message: 'Página não encontrada',
                            description:
                                'Você foi direcionado para Páginas',
                            icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
                            duration: 3
                        });
                        history.replace('/locations/');
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <Layout>
            <PageHeader
                ghost={false}
                onBack={() => history.replace('/locations/')}
                title="Palavras-chave"
                subTitle={title === null ? 'Novo registro' : title}></PageHeader>
            <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
                <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
                    <Form.Item label="Título" name="title"
                        rules={[{
                            required: true,
                            message: 'Favor preencher o Título!'
                        }]}>
                        <Input />
                    </Form.Item>
                    {
                        validationErrors !== '' ?
                            <Form.Item {...tailFormItemLayout}>
                                <Alert
                                    message="Verifique os seguintes erros"
                                    description={validationErrors}
                                    type="error"
                                    showIcon
                                />
                            </Form.Item> : null
                    }
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Salvar
                            </Button>
                    </Form.Item>
                </Form>
            </Content>
            <Footer />
        </Layout>
    );
}

export default Location;