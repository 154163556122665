import React from 'react';
import Master from '../../components/Master/Master';

const Main = () => {    

    return (
        <Master>
            <h1>Área administrativa</h1>
        </Master>
    );
};

export default Main;